import { useEffect, useState } from 'preact/hooks'
import type { Component_Menucomponent } from '../../__generated__/types'
import PreactLink from '../../lib/preact/PreactLink'
import clsx from 'clsx'

export default function MenuSm({ fields }: { fields: Component_Menucomponent }) {
  const [mainMenuOpen, setMainMenuOpen] = useState(false)
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState(-1)

  const submenuFields = fields.menu?.links![activeSubmenuIndex]?.submenu

  const menuOpen = mainMenuOpen || activeSubmenuIndex !== -1

  useEffect(() => {
    if (menuOpen) {
      document.documentElement.classList.add('overflow-hidden')
    } else {
      document.documentElement.classList.remove('overflow-hidden')
    }
  }, [menuOpen])

  return (
    <div class="lg:hidden">
      <button
        class="relative z-50 flex flex-col justify-center items-center"
        onClick={() => {
          setMainMenuOpen(!menuOpen)
          setActiveSubmenuIndex(-1)
        }}
      >
        <div
          class={clsx(
            menuOpen ? '-rotate-45 translate-y-1' : 'rotate-0',
            'w-7 h-[2px] bg-am-orange rounded-2xl my-[2px] transition-transform'
          )}
        ></div>
        <div class={clsx(menuOpen ? 'opacity-0' : 'opacity-100', 'w-7 h-[2px] bg-am-orange rounded-2xl my-[2px] transition-opacity')}></div>
        <div
          class={clsx(
            menuOpen ? 'rotate-45 -translate-y-2' : 'rotate-0',
            'w-7 h-[2px] bg-am-orange rounded-2xl my-[2px] transition-transform'
          )}
        ></div>
      </button>
      {mainMenuOpen && (
        <>
          <div className="fixed top-0 left-0 w-screen h-screen bg-am-navy flex flex-col justify-center items-center">
            <ul class="w-4/5 h-auto">
              {fields.menu?.links?.map((edge, i) => {
                const ms = `${75 + i * 75}ms`

                return (
                  <li class="animate-fade-up my-3" style={{ animationDelay: ms }}>
                    <div class="text-2xl font-headings">
                      {edge?.submenu?.menu ? (
                        <button
                          onClick={() => {
                            setMainMenuOpen(false)
                            setActiveSubmenuIndex(i)
                          }}
                          class="py-1 px-1 flex w-full justify-between items-center focus:ring ring-am-orange"
                        >
                          <span>{edge?.link?.title}</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                            <path
                              class="fill-am-orange"
                              d="M11.3 19.3q-.275-.275-.288-.7t.263-.7l4.9-4.9H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.175l-4.9-4.9q-.275-.275-.263-.7t.288-.7q.275-.275.7-.275t.7.275l6.6 6.6q.15.125.213.313t.062.387q0 .2-.062.375t-.213.325l-6.6 6.6q-.275.275-.7.275t-.7-.275Z"
                            />
                          </svg>
                        </button>
                      ) : (
                        <PreactLink
                          href={edge?.link?.url}
                          target={edge?.link?.target}
                          className="block py-1 px-1 focus:ring ring-am-orange"
                        >
                          {edge?.link?.title}
                        </PreactLink>
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </>
      )}
      {activeSubmenuIndex !== -1 && (
        <div className="fixed bg-am-navy top-0 left-0 w-screen h-screen flex flex-col justify-center items-center z-10">
          <ul class="w-4/5 h-auto">
            <li>
              <button
                onClick={() => {
                  setActiveSubmenuIndex(-1)
                  setMainMenuOpen(true)
                }}
                class="mb-7 flex items-center ring-am-orange focus:ring bg-am-navy-400 px-3 py-2 rounded-md"
              >
                <svg class="rotate-180 mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <path
                    class="fill-white"
                    d="M11.3 19.3q-.275-.275-.288-.7t.263-.7l4.9-4.9H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.175l-4.9-4.9q-.275-.275-.263-.7t.288-.7q.275-.275.7-.275t.7.275l6.6 6.6q.15.125.213.313t.062.387q0 .2-.062.375t-.213.325l-6.6 6.6q-.275.275-.7.275t-.7-.275Z"
                  />
                </svg>
                <span class="underline">vorige</span>
              </button>
            </li>

            {submenuFields?.menu?.map((edge, i) => {
              const ms = `${75 + i * 75}ms`

              return (
                <li class="text-xl font-headings animate-fade-up my-2" style={{ animationDelay: ms }}>
                  <PreactLink href={edge?.link?.url} className="block px-1 focus:ring focus:ring-am-orange py-1">
                    {edge?.link?.title}
                  </PreactLink>
                </li>
              )
            })}
            <li className="animate-fade-up" style={{ animationDelay: `${75 + Number(submenuFields?.menu?.length) * 75}ms` }}>
              <PreactLink
                className="flex mt-7 items-center ring-am-orange focus:ring bg-am-navy-400 px-3 py-2 rounded-md w-fit"
                href={fields.menu?.links![activeSubmenuIndex]?.link?.url}
              >
                <span class="underline">Naar overzicht</span>
                <svg class="ml-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <path
                    class="fill-white"
                    d="M11.3 19.3q-.275-.275-.288-.7t.263-.7l4.9-4.9H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.175l-4.9-4.9q-.275-.275-.263-.7t.288-.7q.275-.275.7-.275t.7.275l6.6 6.6q.15.125.213.313t.062.387q0 .2-.062.375t-.213.325l-6.6 6.6q-.275.275-.7.275t-.7-.275Z"
                  />
                </svg>
              </PreactLink>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}
